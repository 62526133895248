import React from "react";
import "./Navbar.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  console.log(props.isAuthenticated);
  return (
    <div className="navBar" hidden={!props.isAuthenticated}>
      <div className="stocks">
        <Link to="/">Home</Link>
      </div>
      <div className="favourites">
        <Link to="/about">About</Link>
      </div>
      <div className="cart">
        <Link to="/work">Work</Link>
      </div>
      <div className="stocks">
        <Link to="/contact">Contact</Link>
      </div>
    </div>
  );
};

export default Navbar;

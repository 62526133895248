import React from "react";
import "./Login.scss";

import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Auth } from "aws-amplify";
import bgVideo from "../../assets/video/brvideo.MOV";

const Login = (props) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [cookies, setCookie] = useCookies(["token"]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let user = await Auth.signIn("zennature", password);

      console.log(user);
      console.log(password);
      setCookie("token", user !== undefined, { path: "/" });

      navigate("/home");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="login-container">
        <video src={bgVideo} autoPlay loop muted></video>
        <div className="container">
          <h1>Website Under Construction.</h1>
          <form onSubmit={handleLogin} className="form-in">
            <div className="section d-flex">
              <input
                className="form-control"
                type="text"
                placeholder="Password"
                onChange={(evt) => setPassword(evt.target.value)}
              />
              <button type="submit" className="btn btn-primary" hidden>
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;

import React from "react";
import "./App.scss";
import Navbar from "./components/Navbar/Navbar";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

import "bootstrap/dist/css/bootstrap.min.css";
import { useCookies } from "react-cookie";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { useLocation } from "react-router-dom";

Amplify.configure(awsExports);

const App = (props) => {
  const [cookies] = useCookies(["token"]);
  const isAuthenticated = cookies.token == true;

  return (
    <>
      <Navbar isAuthenticated={isAuthenticated} />
      <AnimatedRoutes isAuthenticated={isAuthenticated} />
    </>
  );
};

export default App;

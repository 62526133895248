import React from "react";
import transition from "../transition";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Home = (props) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);
  const isAuthenticated = cookies.token == true;

  useEffect(() => {
    {
      console.log(props);
      isAuthenticated !== true && navigate("/login");
    }
  }, []);
  return <div>Home</div>;
};

export default transition(Home);

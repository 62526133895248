import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "./About/About";
import Work from "./Work/Work";
import Contact from "./Contact/Contact";
import Home from "./Home/Home";
import Login from "./Login/Login";
import { useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = (props) => {
  let isAuthenticated = props.isAuthenticated;
  const location = useLocation();
  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="*"
            element={<Home isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/"
            exact={true}
            element={<Home isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/home"
            element={<Home isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/login"
            element={<Login isAuthenticated={!isAuthenticated} />}
          />
          <Route
            path="/about"
            element={<About isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/work"
            element={<Work isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/contact"
            element={<Contact isAuthenticated={isAuthenticated} />}
          />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default AnimatedRoutes;

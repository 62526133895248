import React from "react";
import "./Work.scss";
import transition from "../transition";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Work = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);
  const isAuthenticated = cookies.token == true;

  useEffect(() => {
    {
      isAuthenticated !== true && navigate("/login");
    }
  }, []);
  return (
    <>
      <div className="Work">Work Component</div>
    </>
  );
};

export default transition(Work);

import React from "react";
import transition from "../transition";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Contact = (props) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);
  const isAuthenticated = cookies.token == true;

  useEffect(() => {
    {
      isAuthenticated !== true && navigate("/login");
    }
  }, []);
  return <div>Contact</div>;
};

export default transition(Contact);
